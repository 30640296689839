import { useEffect } from 'react';
import '../styles/loadingScreen.css';

const LoadingScreen = ({ setLoading }) => {
  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, [setLoading]);

  return (
    <div className="loading-screen">
      <img src="/LOGO_BLANCO.png" alt="Logo" />
      <h2>Miguel Olteanu</h2>
    </div>
  );
};

export default LoadingScreen;
