import React, { useState } from "react";
import { FaEnvelope, FaPhone, FaLinkedin, FaGithub } from "react-icons/fa";
import emailjs from "emailjs-com";
import "../../styles/components/Contact.css";
import { useLanguage } from "../../hooks/LanguageContext";

function Contact() {
  const { isEnglish } = useLanguage();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [isSending, setIsSending] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorFields, setErrorFields] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setErrorFields({
      ...errorFields,
      [e.target.name]: false,
    });
  };

  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let hasError = false;
    let invalidEmail = false;

    const newErrorFields = {};
    for (const field in formData) {
      if (!formData[field]) {
        newErrorFields[field] = true;
        hasError = true;
      }
    }

    if (!isValidEmail(formData.email)) {
      invalidEmail = true;
      hasError = true;
    }

    setErrorFields(newErrorFields);

    if (hasError) {
      if (invalidEmail) {
        setErrorMessage(
          isEnglish
            ? "Please enter a valid email address."
            : "Por favor, introduce un correo electrónico válido."
        );
      } else {
        setErrorMessage(
          isEnglish
            ? "Please fill in all fields."
            : "Por favor, rellena todos los campos."
        );
      }
      return;
    }

    setIsSending(true);
    setErrorMessage("");

    emailjs
      .send(
        "service_tdbn04g",
        "template_hgjaujx",
        {
          user_name: formData.name,
          user_email: formData.email,
          subject: formData.subject,
          message: formData.message,
        },
        "rk9LPtv7pxF-aPg0I"
      )
      .then((response) => {
        console.log("Email sent:", response.status, response.text);
        setIsSending(false);
        setSendSuccess(true);
        setFormData({ name: "", email: "", subject: "", message: "" });
        setErrorFields({});
      })
      .catch((err) => {
        console.error("Error sending email:", err);
        setIsSending(false);
        setErrorMessage(
          isEnglish
            ? "There was an error sending the message. Please try again later."
            : "Hubo un error al enviar el mensaje. Inténtalo de nuevo más tarde."
        );
      });
  };

  return (
    <div className="contact-container">
      <p className="contact-description">
        {isEnglish
          ? "Do you have any questions or proposals? Send me an email through the form or get in touch through my networks!"
          : "¿Tienes alguna pregunta o propuesta? ¡Envíame un correo a través del formulario o ponte en contacto a través de mis redes!"}
      </p>

      {errorMessage && <p className="error-message">{errorMessage}</p>}
      {sendSuccess && (
        <p className="success-message">
          {isEnglish
            ? "Message sent successfully!"
            : "¡Mensaje enviado con éxito!"}
        </p>
      )}

      <form className="contact-form" onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder={isEnglish ? "Name" : "Nombre"}
          value={formData.name}
          onChange={handleChange}
          className={`form-input ${errorFields.name ? "error" : ""}`}
        />
        <input
          name="email"
          placeholder={isEnglish ? "Email" : "Correo electrónico"}
          value={formData.email}
          onChange={handleChange}
          className={`form-input ${errorFields.email ? "error" : ""}`}
        />
        <input
          type="text"
          name="subject"
          placeholder={isEnglish ? "Subject" : "Asunto"}
          value={formData.subject}
          onChange={handleChange}
          className={`form-input ${errorFields.subject ? "error" : ""}`}
        />
        <textarea
          name="message"
          placeholder={isEnglish ? "Write your message" : "Escribe tu mensaje"}
          value={formData.message}
          onChange={handleChange}
          className={`form-textarea ${errorFields.message ? "error" : ""}`}
        ></textarea>
        <button type="submit" className="form-button" disabled={isSending}>
          {isEnglish ? "Send" : "Enviar"}
        </button>
      </form>

      <div className="contact-info">
        <div className="info-item">
          <FaEnvelope className="contact-icon" />
          <p>miguelolte2003@gmail.com</p>
        </div>
        <div className="info-item">
          <FaPhone className="contact-icon" />
          <p>+34 654306689</p>
        </div>
        <div className="contact-socials">
          <a
            href="https://www.linkedin.com/in/miguel-olteanu/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin />
          </a>
          <a
            href="https://github.com/Oltee10"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGithub />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contact;
