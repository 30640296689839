import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Home from "./pages/Home";
import Projects from "./pages/AllProjects/Projects";
import About from "./pages/About";
import Contact from "./pages/Contact";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "../styles/MainContent.css";
import useWindowWidth from "../hooks/useWindowWidth";
import { LanguageProvider, useLanguage } from "../hooks/LanguageContext";

// Función para mapear rutas a nombres de secciones
const getRouteName = (pathname, isEnglish) => {
  const routes = {
    "/": { en: "HOME", es: "INICIO" },
    "/projects": { en: "PROJECTS", es: "PROYECTOS" },
    "/about": { en: "ABOUT ME", es: "SOBRE MÍ" },
    "/contact": { en: "CONTACT", es: "CONTACTO" },
  };

  return routes[pathname]
    ? routes[pathname][isEnglish ? "en" : "es"]
    : routes["/"][isEnglish ? "en" : "es"];
};

function MainContent() {
  const isMobile = useWindowWidth();
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  useEffect(() => {
    setIsSidebarVisible(!isMobile);
  }, [isMobile]);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  // Componente para manejar las rutas dentro del Router
  const AppRouter = () => {
    const location = useLocation();
    const { isEnglish } = useLanguage();
    const currentRoute = getRouteName(location.pathname, isEnglish);

    return (
      <>
        <Navbar
          toggleSidebar={toggleSidebar}
          isMobile={isMobile}
          currentRoute={currentRoute}
        />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </>
    );
  };

  return (
    <LanguageProvider>
      <Router>
        <div
          className={`mainContent-container ${
            isSidebarVisible ? "sidebar-visible" : "sidebar-hidden"
          }`}
        >
          <Sidebar
            isSidebarVisible={isSidebarVisible}
            toggleSidebar={toggleSidebar}
            isMobile={isMobile}
          />
          <div className="main-section">
            <AppRouter />
          </div>
        </div>
      </Router>
    </LanguageProvider>
  );
}

export default MainContent;
