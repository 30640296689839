import React from "react";
import "../../styles/components/Home.css";
import { useLanguage } from "../../hooks/LanguageContext";

function Home() {
  const { isEnglish } = useLanguage();

  return (
    <div className="home-content">
      <img src="/LOGO_BLANCO.png" alt="Logo grande" className="large-logo" />
      <hr className="divider" />
      <p className="motto">
        {isEnglish
          ? "Daily progress is the true engine of success."
          : "El progreso diario es el verdadero motor del éxito."}
      </p>
    </div>
  );
}

export default Home;
