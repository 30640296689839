import React, { createContext, useState, useContext } from 'react';

// Crear contexto
const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

// Proveedor de contexto
export const LanguageProvider = ({ children }) => {
  const [isEnglish, setIsEnglish] = useState(false);

  const toggleLanguage = () => {
    setIsEnglish(prevIsEnglish => !prevIsEnglish);
  };

  return (
    <LanguageContext.Provider value={{ isEnglish, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
