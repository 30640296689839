import React, { useState, useRef, useEffect } from "react";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { FaEye, FaCamera, FaTimes } from "react-icons/fa";
import "../../../styles/components/AllProjects/ProjectCard.css";
import { useLanguage } from "../../../hooks/LanguageContext";

function ProjectCard({
  title,
  description,
  image,
  technologies,
  link,
  projectImages,
}) {
  const { isEnglish } = useLanguage();
  const descriptionText = isEnglish ? description.en : description.esp;
  const [isFlipped, setIsFlipped] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const isMobile = useWindowWidth();
  const scrollContainerRef = useRef(null);

  const handleInteraction = () => {
    if (isMobile) {
      setIsFlipped(!isFlipped);
    }
  };

  const handleImageClick = (e) => {
    e.stopPropagation();
    setShowImages(true);
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    let isDown = false;
    let startX;
    let scrollLeft;

    const handleMouseDown = (e) => {
      isDown = true;
      startX = e.pageX - scrollContainer.offsetLeft;
      scrollLeft = scrollContainer.scrollLeft;
      scrollContainer.style.cursor = "grabbing";
    };

    const handleMouseLeave = () => {
      isDown = false;
      scrollContainer.style.cursor = "grab";
    };

    const handleMouseUp = () => {
      isDown = false;
      scrollContainer.style.cursor = "grab";
    };

    const handleMouseMove = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - scrollContainer.offsetLeft;
      const walk = (x - startX) * 2; // Adjust scrolling speed
      scrollContainer.scrollLeft = scrollLeft - walk;
    };

    if (scrollContainer) {
      scrollContainer.addEventListener("mousedown", handleMouseDown);
      scrollContainer.addEventListener("mouseleave", handleMouseLeave);
      scrollContainer.addEventListener("mouseup", handleMouseUp);
      scrollContainer.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("mousedown", handleMouseDown);
        scrollContainer.removeEventListener("mouseleave", handleMouseLeave);
        scrollContainer.removeEventListener("mouseup", handleMouseUp);
        scrollContainer.removeEventListener("mousemove", handleMouseMove);
      }
    };
  }, [showImages]);

  return (
    <div className="card-container">
      <div
        className={`card ${isFlipped ? "flipped" : ""}`}
        onClick={handleInteraction}
      >
        <div className="content">
          <div className="front">
            <div className="front-content">
              <img src={image} alt={title} className="card-image" />
              <h3 className="card-title">{title}</h3>
              <div className="card-technologies">
                {technologies.map((tech, index) => (
                  <span key={index} className="tech-badge">
                    {tech}
                  </span>
                ))}
              </div>
            </div>
          </div>

          <div className="back">
            <div className="back-content">
              <h3 className="card-title">{title}</h3>
              <p className="card-description">{descriptionText}</p>
              <div className="card-technologies">
                {technologies.map((tech, index) => (
                  <span key={index} className="tech-badge">
                    {tech}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card-buttons">
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="link-button"
        >
          <FaEye />
        </a>
        <button onClick={handleImageClick} className="photos-button">
          <FaCamera />
        </button>
      </div>

      {showImages && (
        <div className="image-modal" onClick={() => setShowImages(false)}>
          <button
            className="image-modal-close"
            onClick={() => setShowImages(false)}
          >
            <FaTimes />
          </button>
          <div
            className="image-modal-content"
            ref={scrollContainerRef}
            onClick={(e) => e.stopPropagation()}
          >
            {projectImages.map((img, index) => (
              <img key={index} src={img} alt={`Project images ${index + 1}`} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default ProjectCard;
