import React from "react";
import "../styles/components/Sidebar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUser,
  faLightbulb,
  faPhone,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../hooks/LanguageContext";

function Sidebar({ isSidebarVisible, toggleSidebar, isMobile }) {
  const { isEnglish } = useLanguage();
  const navigate = useNavigate();

  // Function that navigates and closes the sidebar if on mobile
  const handleNavigation = (path) => {
    navigate(path);
    if (isMobile) {
      toggleSidebar();
    }
  };

  return (
    <div className={`sidebar ${isSidebarVisible ? "show" : "hidden"}`}>
      {/* Close button only visible on mobile */}
      {isMobile && isSidebarVisible && (
        <button className="close-btn" onClick={toggleSidebar}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      )}

      <img
        src="LOGO_BLANCO.png"
        alt="Logo"
        className="logo"
        onClick={() => handleNavigation("/")}
      />
      <h2 className="sidebar-name" onClick={() => handleNavigation("/")}>
        Miguel Olteanu
      </h2>
      <p className="sidebar-description">
        {isEnglish
          ? "Software Engineer Student"
          : "Ingeniero de Software en Formación"}
      </p>

      <button className="button" onClick={() => handleNavigation("/")}>
        <FontAwesomeIcon icon={faHome} className="icon" />
        {isEnglish ? "Home" : "Inicio"}
      </button>

      <button className="button" onClick={() => handleNavigation("/projects")}>
        <FontAwesomeIcon icon={faLightbulb} className="icon" />
        {isEnglish ? "Projects" : "Proyectos"}
      </button>

      <button className="button" onClick={() => handleNavigation("/about")}>
        <FontAwesomeIcon icon={faUser} className="icon" />
        {isEnglish ? "About Me" : "Sobre Mi"}
      </button>

      <button className="button" onClick={() => handleNavigation("/contact")}>
        <FontAwesomeIcon icon={faPhone} className="icon" />
        {isEnglish ? "Contact Me" : "Contáctame"}
      </button>
    </div>
  );
}

export default Sidebar;
