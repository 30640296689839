import { useState } from "react";
import LoadingScreen from "./hooks/loadingScreen";
import MainContent from "./components/MainContent";

function App() {
  const [loading, setLoading] = useState(true);

  return loading ? (
    <LoadingScreen setLoading={setLoading} />
  ) : (
      <MainContent />
  );
}

export default App;
