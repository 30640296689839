import React from "react";
import { useLanguage } from "../hooks/LanguageContext"; // Importar el contexto
import "../styles/components/Navbar.css";
import Flags from "country-flag-icons/react/3x2";

function Navbar({ toggleSidebar, isMobile, currentRoute }) {
  const { isEnglish, toggleLanguage } = useLanguage(); // Usar el contexto del idioma

  return (
    <div className="navbar">
      {isMobile && (
        <button className="sidebar-toggle" onClick={toggleSidebar}>
          ☰
        </button>
      )}
      <div className="route-name">{currentRoute}</div>

      <div className="navbar-controls">
        <label className="switch">
          <input type="checkbox" checked={isEnglish} onClick={toggleLanguage} />
          <div className="slider">
            <span className="flag spain">
              <Flags.ES />
            </span>
            <span className="flag uk">
              <Flags.GB />
            </span>
          </div>
        </label>
      </div>
    </div>
  );
}

export default Navbar;